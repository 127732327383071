@font-face {
  font-family: 'Caja-md';
  src: url("./fonts/MetaPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Caja-Black';
  src: url("./fonts/MetaPro-Black.otf") format("opentype");
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root {
  background-image: url("/src/img/fondo-cpa.jpg");
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

.mainSection {
  /* para bajar footer */
  flex-grow: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DDDDDD;
  position: relative;
}

.colorMain {
  background: rgb(255, 255, 255);
}

/* Efectos menu lateral de pagina */
.link-item {
  border: 1px solid #ccc;
  padding: 3px;
  width: 90%;
  text-align: center;
  margin: 1%;
  font-family: 'Roboto', sans-serif;
  background-color: #008185;
  color: white;
}

/* flecha en menu lateral */
.dropdown-toggle {
  color: white;
  font-size:19px;
  margin-left: 10px;
}

.link-item {
  transition: background-color 0.3s, color 0.3s;
  /* Transición suave de 0.3 segundos */
}

.link-item:hover {
  background-color: #F15E21;
  /* Cambia el color de fondo al pasar el mouse */
  color: white;
  /* Cambia el color de las letras al pasar el mouse */
}

/* menu lateral para elimianr borde */
.custom-card {
  /* border-color: rgb(255, 255, 255); */
  border:transparent;
  background: transparent;
}
.dropdown-menu {
  /* border-color: rgb(255, 255, 255); */
  border:transparent;
  background: transparent;
}

/* Fin efecto menu lateral */

.letraCaja {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

/* Efecto de tabla resultados */
.row-tabla {
  justify-content: center;
  display: flex;
  margin-top: 1%;
}

.tabla-cpa {
  width: 100%;
  margin-bottom: 20px;
  font: 10px;
  font-family: 'Roboto', sans-serif;
  border-collapse: collapse;
}


.letraFooter{
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-optical-sizing: auto;
    text-align: start;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
}


/* Estilos para dispositivos móviles (ancho máximo de 767px) */
@media (max-width: 767px) {
  .letraFecha {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 12px; /* XX es el tamaño de fuente para dispositivos móviles */
  }
}